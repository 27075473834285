<template>
  <div class="bg" v-if="show">
    <div class="container animation-big">
      <div class="close cursor-pointer" @click="close"></div>
      <slot></slot>
      <div class="actions">
        <div class="action-item cursor-pointer cancel" @click="close">
          <span>取消</span>
        </div>
        <div class="action-item cursor-pointer confirm" @click="submit">
          <span>确定</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    {
      return {};
    }
  },
  created() {},
  mounted() {},
  methods: {
    submit() {
      this.$emit("submit");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(46, 46, 46, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    width: 920px;
    height: 612px;
    box-sizing: border-box;
    padding: 72px 0 190px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 26px;
      background: linear-gradient(92deg, #9865ff 0%, #3b70c9 56%, #1fe9ff 99%);
    }
    .close {
      width: 32px;
      height: 32px;
      background: url(#{$PublicEnv}/index/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 32px;
      top: 40px;
    }
    .actions {
      position: absolute;
      bottom: 70px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .action-item {
        &.cancel {
          width: 310px;
          height: 80px;
          background: url(#{$PublicEnv}/activity_my/activity_back_bg.png);
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #4922ec;
          }
        }
        &.confirm {
          margin-left: 60px;
          width: 310px;
          height: 119px;
          background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          padding-top: 14px;
          box-sizing: border-box;
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .bg {
    .container {
      width: 3159px;
      height: 2765px;
      padding: 461px 0 650px 0px;
      &::before {
        height: 40px;
      }
      .close {
        width: 154px;
        height: 154px;
        right: 87px;
        top: 123px;
      }
      .actions {
        bottom: 292px;
        .action-item {
          &.cancel {
            width: 1229px;
            height: 358px;
            span {
              font-size: 195px;
            }
          }
          &.confirm {
            margin-left: 154px;
            width: 1229px;
            height: 471.11px;
            padding-top: 32px;
            span {
              font-size: 195px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>
