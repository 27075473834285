<template>
  <div :class="`top ${black ? 'black' : ''}`">
    <div
      v-if="!userInfo && $utils.checkWxOrMini(0)"
      class="login cursor-pointer"
      @click.stop.prevent="popLoginOpen"
    >
      <span>登录</span>
    </div>
    <div
      v-if="!userInfo && $utils.checkWxOrMini(1) && weappPathFinished"
      class="login cursor-pointer"
    >
      <WxOpenLaunchWeapp :path="weappPath">
        <div :style="weappStyle.div">
          <span :style="weappStyle.span">登录</span>
        </div>
      </WxOpenLaunchWeapp>
    </div>
    <div
      v-if="userInfo"
      class="login-user cursor-pointer"
      @mouseenter="loginNavOpen"
      @mouseleave="loginNavClose"
      @click="loginNavOpen"
    >
      <div class="user-avatar">
        <img v-if="userInfo.avatar" :src="userInfo.avatar" />
      </div>
      <span class="name">{{ userInfo.user_name }}</span>
      <div :class="`login-nav ${loginNavShow ? 'active' : ''}`">
        <span class="cursor-pointer" @click.stop.prevent="logoutOpen"
          >退出登录</span
        >
      </div>
    </div>
    <action-confirm
      :show="logoutShow"
      @close="logoutClose"
      @submit="logoutCallback"
    >
      <div class="action-confirm-tips">
        <span>确定要退出登录吗？</span>
      </div>
    </action-confirm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WxOpenLaunchWeapp from "@/components/WxOpenLaunchWeapp.vue";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
import { routesData, routesModuleData, routesConvert } from "@/router/index";
export default {
  components: { WxOpenLaunchWeapp, ActionConfirm },
  props: {
    black: {
      type: Boolean,
      default: false,
    },
    weappPath: {
      type: String,
      default: "",
    },
    weappPathFinished: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loginNavShow: false,
      logoutShow: false,
      productNavShow: false,
      weappStyle: {
        div: {
          width: "100%",
          height: "100%",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          position: "absolute",
          left: "0",
          top: "0",
        },
        span: {
          fontSize: "14.4px",
          fontFamily: "Source Han Sans CN-Light, Source Han Sans CN",
          fontWeight: "300",
          color: "#ffffff",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {},
  methods: {
    userManualOpen() {
      window.open("https://docs.qq.com/doc/DYWxhaVVGTktIb2pu");
    },
    productNavOpen() {
      this.productNavShow = true;
    },
    productNavClose() {
      this.productNavShow = false;
    },
    logoutClose() {
      this.logoutShow = false;
    },
    goActivity() {
      this.loginNavClose();
      const router = {
        name: routesConvert(routesModuleData.manage, true).name,
        query: {
          tab: "activity",
        },
      };
      if (
        this.$route.name != routesConvert(routesModuleData.manage, true).name
      ) {
        this.$router.push(router);
      } else {
        this.$emit("go", "activity");
      }
    },
    goResource() {
      this.loginNavClose();
      const router = {
        name: routesConvert(routesModuleData.manage, true).name,
        query: {
          tab: "resource",
        },
      };
      if (
        this.$route.name != routesConvert(routesModuleData.manage, true).name
      ) {
        this.$router.push(router);
      } else {
        this.$emit("go", "resource");
      }
    },
    goIndex() {
      this.loginNavClose();
      this.productNavClose();
      if (this.$route.name != "Index") {
        sessionStorage.setItem("backIndex", 1);
        this.$router.replace({
          name: routesData.index.name,
        });
      }
    },
    goEngine() {
      this.productNavClose();
      location.href = "http://cy.idea888.com/";
    },
    logoutOpen() {
      this.logoutShow = true;
    },
    logoutCallback() {
      this.loginNavClose();
      this.logoutShow = false;
      this.$storage.clearToken();
      this.$store.dispatch("clearUserInfo", "");
      this.$store.dispatch("clearConfig", "");
      this.$toast.success("已退出登录");
      this.$emit("logout");
      this.goIndex();
    },
    loginNavOpen() {
      // if (!this.$utils.isMobile().wechat) {
      this.loginNavShow = true;
      // }
    },
    loginNavClose() {
      this.loginNavShow = false;
    },
    popLoginOpen() {
      this.$emit("open");
    },
  },
};
</script>
<style lang="scss" scoped>
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
.top {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 0 392px;
  position: absolute;
  top: 104px;
  z-index: 10;
  .logo {
    flex-shrink: 0;
    width: 166.63px;
    height: 80px;
    background: url(#{$PublicEnv}/activity/top_logo_3.png);
    background-size: 100% 100%;
  }
  .others {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin-left: 220px;
    .others-item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      margin-right: 136px;
      span {
        font-size: 40px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 350;
        color: #ffffff;
      }
      img {
        width: 26px;
        height: 26px;
        margin-left: 12px;
        transform: rotate(180deg);
      }
      &.product {
        transition: all 3s ease-in-out;
        &.active {
          img {
            transform: rotate(0);
          }
        }
        .product-nav {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -125px;
          width: 250px;
          height: 248px;
          border-radius: 22px;
          background: rgba(44, 54, 96, 1);
          visibility: hidden;
          opacity: 0;
          transition: all 0.4s ease-in-out;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          box-sizing: border-box;
          padding: 48px 0;
          &.active {
            visibility: visible;
            top: 100px;
            opacity: 1;
          }
          span {
            font-size: 30px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            width: 100%;
            height: 64px;
            display: block;
            box-sizing: border-box;
            padding: 0 54px;
            line-height: 64px;
            transition: all 0.3s ease-in-out;
            &:first-child {
              background: linear-gradient(
                90deg,
                rgba(68, 86, 176, 1),
                rgba(36, 60, 115, 0) 60%
              );
            }
          }
        }
      }
    }
  }
  .login {
    flex-shrink: 0;
    width: 200px;
    height: 60px;
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: rgba(75, 72, 83, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    position: relative;
    span {
      font-size: 36px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .login-user {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .user-avatar {
      width: 80px;
      height: 80px;
      margin-right: 14px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    span {
      font-size: 28px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .login-nav {
      position: absolute;
      top: 0;
      left: 0;
      width: 250px;
      height: 160px;
      border-radius: 22px;
      background: linear-gradient(
        45deg,
        rgba(31, 39, 53, 1),
        rgba(51, 58, 72, 1)
      );
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 48px 0;
      &.active {
        visibility: visible;
        top: 100px;
        opacity: 1;
      }
      span {
        font-size: 30px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        width: 100%;
        height: 64px;
        display: block;
        box-sizing: border-box;
        padding: 0 54px;
        line-height: 64px;
        transition: all 0.3s ease-in-out;
        &:first-child {
          background: linear-gradient(
            90deg,
            rgba(68, 86, 176, 1),
            rgba(36, 60, 115, 0) 60%
          );
        }
      }
    }
  }
  &.black {
    padding: 0 160px;
    position: absolute;
    top: 60px;
    .logo {
      background: url(#{$PublicEnv}/activity/top_logo_3_black.png);
      background-size: 100% 100%;
    }
    .login-user {
      .name {
        color: rgba(55, 55, 55, 1);
      }
    }
  }
}
@media (orientation: portrait) {
  .top {
    top: 113px;
    padding: 0 215px;
    .logo {
      width: 532px;
      height: 256px;
    }
    .others {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      margin-left: 282px;
      .others-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        span {
          font-size: 160px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 350;
          color: #ffffff;
        }
        img {
          width: 184px;
          height: 184px;
          margin-left: 67px;
          transform: rotate(180deg);
        }
        &.product {
          transition: all 3s ease-in-out;
          &.active {
            img {
              transform: rotate(0);
            }
          }
          .product-nav {
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -683.5px;
            width: 1367px;
            height: 1147px;
            border-radius: 56px;
            background: rgba(44, 54, 96, 1);
            padding: 220px 0;
            &.active {
              visibility: visible;
              top: 350px;
              opacity: 1;
            }
            span {
              width: 100%;
              height: 271px;
              line-height: 271px;
              font-size: 184px;
              padding: 0 276px;
              &:first-child {
              }
            }
          }
        }
      }
    }
    .login {
      width: 717px;
      height: 266px;
      border-radius: 123px;
      span {
        font-size: 143px;
      }
    }
    .login-user {
      .user-avatar {
        width: 258px;
        height: 258px;
      }
      img {
        width: 258px;
        height: 258px;
        margin-right: 46px;
      }
      span {
        font-size: 92px;
      }
      .login-nav {
        width: 1367px;
        height: 1500px;
        padding: 220px 0;
        line-height: 1500px;
        border-radius: 56px;
        right: 0;
        left: unset;
        &.active {
          top: 350px;
        }
        span {
          width: 100%;
          height: 271px;
          line-height: 271px;
          font-size: 184px;
          padding: 0 276px;
          &:first-child {
          }
        }
      }
    }
    &.black {
      padding: 0 230px;
      position: absolute;
      top: 138px;
    }
  }
  .action-confirm-tips {
    span {
      font-size: 184px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}
</style>
