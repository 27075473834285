<template>
  <div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%">
    <wx-open-launch-weapp
      username="gh_6853edaf1b86"
      :path="path"
      style="position: absolute; left: 0; top: 0; width: 100%; height: 100%"
    >
      <div :is="'script'" type="text/wxtag-template">
        <slot></slot>
      </div>
    </wx-open-launch-weapp>
  </div>
</template>
<script>
export default {
  props: {
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
